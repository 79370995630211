




























import Vue from "vue";
export default Vue.extend({
  name: "help",
  methods: {
    openWhatsapp() {
      window.open("https://wa.me/18299629673","_blank")
    },
    openPhone() {
        window.open("tel:+1829-962-9673","_blank")
    },
    openEmail() {
      window.open("mailto:info@banmovil.com","_blank")
    },
  },
});
